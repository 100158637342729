@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TT Firs Neue Trial Var Roman";
  font-display: block;
  src: url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.eot");
  src: url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/5d142601b903c50f97692721f594aa0a.svg#TT Firs Neue Trial Var Roman")format("svg");
}

@font-face {
  font-family: "Quicksand";
  font-display: block;
  src: url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.eot");
  src: url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/21bfa7d415073b05f611f3d15588ffd8.svg#Quicksand")format("svg");
}

body {
  font-family: "Quicksand";
  background: #070A0F;
}

.play-button{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

.font-TT {
  font-family: "TT Firs Neue Trial Var Roman";
}

.font-quicksand {
  font-family: "Quicksand";
}

.blur-ellipse1 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  left: -5%;
  top: 15%;
  transform: translate(-5%, -15%);
}

.blur-ellipse2 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  right: -20%;
  bottom: 15%;
  transform: translate(-20%, -15%);
}

.blur-ellipse3 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  left: -5%;
  top: -25%;
  transform: translate(-5%, -25%);
}

.blur-ellipse4 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  right: -20%;
  bottom: -50%;
  transform: translate(-20%, -10%);
}

.blur-ellipse5 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  left: -5%;
  top: -0%;
  transform: translate(-5%, -0%);
}

.blur-ellipse6 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  right: -20%;
  bottom: -0%;
  transform: translate(-20%, -0%);
}

.blur-ellipse7 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  left: -5%;
  top: -0%;
  transform: translate(-5%, -0%);
}

.blur-ellipse8 {
  z-index: -1;
  width: 50vw;
  height: 50vh;
  opacity: 0.85;
  filter: blur(35vh);
  left: 50%;
  top: 0%;
  transform: translate(-50%, -0%);
}

.wave-position {
  position: absolute;
  top: -10%;
  left: 0;
  transform: translate(0, -10%);
}

.canvas-fr{
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 767px) {
  .wave-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(4.5);
  }

  .line-position {
    /* left: -50%; */
    position: relative;
    transform: scale(3);
  }
  
  .line-position2 {
    /* right: -50%; */
    position: relative;
    transform: scale(3);
  }

  .blur-ellipse1 {
    filter: blur(25vh);
  }

  .blur-ellipse2 {
    filter: blur(25vh);
  }
}

/* Styles for Tablet Devices (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .blur-ellipse1 {
    filter: blur(25vh);
  }

  .blur-ellipse2 {
    filter: blur(25vh);
  }

  .wave-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
}

/* Additional styles for form elements */
.influencer-form {
  color: white;
  
}

.influencer-form input[type="email"],
.influencer-form input[type="text"] {
  padding: 10px;
  margin: 10px 0;
  width: 90%;
  background-color: #333;
  border: 1px solid black;
  color: white ;
}

.influencer-form button {
  background-color: #3396FF; /* Same green as before for consistency */
  /* ...other button styles... */
}

.influencer-form .checkbox-group {
  margin: 20px 0;
}

/* Styles for the qualifying criteria */
.qualifying-criteria {
  text-align: left;
  margin: 20px;
  padding: 20px;
  border-top: 1px solid #4CAF50;
}

.qualifying-criteria h3 {
  color: #4CAF50;
}

/* Styles for Desktop Devices (1024px and above) */
@media (min-width: 1024px) {
  /* Add your desktop-specific styles here */
}